<template>
  <div class="outer_page_content flex_column">
    <my-bread-crumb :breadMenu="breadMenu"></my-bread-crumb>
    <div class="inner_content_page">
      <component
        v-if="currentComponent"
        :itemList="itemList"
        :bookId="bookId"
        :typeId="typeId"
        :gameId="gameId"
        :bookChapterId="bookChapterId"
        :is="currentComponent"
      ></component>
    </div>
  </div>
</template>

<script>
import { fetchGameTaskInfo } from '@/api/service';
import MyBreadCrumb from '@/components/BreadCrumb';
import globalConfig from '@/store/global_config';
export default {
  components: {
    MyBreadCrumb
  },
  data() {
    return {
      templatePath: '',
      itemList: [],
      currentComponent: '',
      breadMenu: [
        {
          title: '选择教材',
          path: '/book'
        },
        {
          title: '教材详情',
          isBack: true
        },
        {
          title: '学习详情'
        }
      ]
    };
  },
  methods: {
    // 获取游戏详情
    fetchGameInfo(wordType) {
      fetchGameTaskInfo({
        bookId: this.bookId,
        bookChapterId: this.bookChapterId,
        typeId: this.typeId,
        gameId: this.gameId
      }).then(response => {
        this.itemList = response.data;
        this.templatePath = wordType;
        const componetPathObj = {
          'copy-reading': 'Study/Word/copyReading',
          'listen-defense': 'Study/Word/listen-defense',
          'see-choose': 'Study/Word/seeChoose',
          'word-choose-audio': 'Study/Word/word-choose-audio',
          'word-choose-translate': 'Study/Word/word-choose-translate',
          'translate-choose-audio': 'Study/Word/TranslateChooseAudio',
          'listen-group': 'Study/Word/ListenGroup',
          'listen-spell': 'Study/Word/ListenSpell',
          'pic-choose-audio': 'Study/Word/PicChooseAudio',
          'word-audio-pic': 'Study/Word/wordAudioPic',
          'pic-choose-translate': 'Study/Word/PicChooseTranslate',
          'word-choose-pic': 'Study/Word/WordChoosePic',
          'see-translate': 'Study/Word/SeeTranslate',
          'see-spell': 'Study/Word/SeeSpell',
          'word-explanation': 'Study/Word/WordExplanation',
          'word-audio': 'Study/Word/WordAudio'
        };
        this.currentComponent = require('@/components/' +
          componetPathObj[this.templatePath]).default;
      });
    }
  },

  mounted() {
    globalConfig.commit('changeVideoProgress', 0);
    const { wordType, bookId, chapterId, typeId, gameId } = this.$route.params;
    this.bookId = bookId;
    this.bookChapterId = chapterId;
    this.typeId = typeId;
    this.gameId = gameId;
    this.fetchGameInfo(wordType);
  }
};
</script>

<style lang="less" scoped>
.outer_page_content {
  .inner_content_page {
    background-color: white;
    flex: 2;
    position: relative;
  }
}
</style>
